<template>
  <v-data-table
    :headers="headers"
    :items="users"
    sort-by="calories"
    class="elevation-1"
    :search="search"
    :loading="loadingTable"
    :loading-text="loadingText"
  >
    <template v-slot:[`item.status`]>
      <v-chip color="success"> active</v-chip>
    </template>

    <template v-slot:top>
      <div class="tw-px-2 md:tw-px-6 tw-mb-4">
        <card-title icon="mdi-account-circle" :is-image="false"
          >APA Staff</card-title
        >
      </div>

      <v-dialog v-model="dialog" max-width="700px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.id"
                    label="id"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.fname"
                    label="First Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.lname"
                    label="Last Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.email_address"
                    label="Email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.department"
                    label="Department"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.role"
                    label="Role"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.extension"
                    label="Extension No."
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="save">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" color="secondary" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon small color="error" @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import CardTitle from '@/components/CardTitle'
export default {
  components: { CardTitle },
  data: () => ({
    dialog: false,
    search: '',
    headers: [
      {
        text: 'Id',
        align: 'start',
        sortable: true,
        value: 'id',
      },
      {
        text: 'First Name',
        align: 'start',
        sortable: true,
        value: 'fname',
      },
      {
        text: 'Last Name',
        align: 'start',
        sortable: true,
        value: 'lname',
      },
      { text: 'Email', value: 'email_address' },
      { text: 'Department', value: 'department' },
      { text: 'Role', value: 'role' },
      { text: 'Extension No.', value: 'extension' },
      { text: 'Status', value: 'status' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      id: '',
      fname: '',
      lname: '',
      email: '',
      department: '',
      role: '',
      extension: '',
    },
    defaultItem: {
      name: '',
      email: '',
      dept: '',
      role: '',
      extension: '',
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    ...mapState(['users', 'loadingTable']),
    ...mapGetters(['loadingText']),
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  mounted() {
    this.$store.dispatch('fetchUser')
  },
  created() {
    this.$store.state.loadingTable = true
    this.$store.state.loadingText = 'Loading... Please wait'
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = { ...item }
      this.dialog = true
    },

    deleteItem(item) {
      const index = this.users.indexOf(item)
      confirm('Are you sure you want to delete this item?') &&
        this.users.splice(index, 1)
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        this.desserts[this.editedIndex] = { ...this.editedItem }
      } else {
        this.desserts.push(this.editedItem)
      }
      this.close()
    },
  },
}
</script>
